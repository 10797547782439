import React from "react"
import { Grid, Box, Flex, Text, Button, Label, Input, Divider } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"

const ColorSwatch = ({ name }) => {
  return (
    <Flex sx={{ flexDirection: "column", alignItems: "center", mr: 3, mb: 3 }}>
      <Box sx={{ width: 200, height: 100, bg: name, mb: 1 }} />
      <Text variant="label">{name}</Text>
    </Flex>
  )
}

const StyleGuidePage = () => (
  <Layout>
    <SEO title="Style Guide" />
    
    <Breadcrumbs title="Style Guide" />
    
    <Grid variant="base">
      <Box>
        <Text variant="label" sx={{ mb: 3, color: "grayMedium" }}>Colors</Text>
        <Flex sx={{ flexWrap: "wrap" }}>
          <ColorSwatch name="text" />
          <ColorSwatch name="background" />
        </Flex>
        <Flex sx={{ flexWrap: "wrap" }}>
          <ColorSwatch name="grayXDark" />
          <ColorSwatch name="grayDark" />
          <ColorSwatch name="grayMedium" />
          <ColorSwatch name="grayLight" />
          <ColorSwatch name="grayXLight" />
        </Flex>
        <Flex sx={{ flexWrap: "wrap" }}>
          <ColorSwatch name="black" />
          <ColorSwatch name="white" />
        </Flex>
        <Flex sx={{ flexWrap: "wrap" }}>
          <ColorSwatch name="fighter" />
          <ColorSwatch name="invoker" />
          <ColorSwatch name="ranger" />
          <ColorSwatch name="naturalist" />
          <ColorSwatch name="doctor" />
          <ColorSwatch name="spy" />
          <ColorSwatch name="magician" />
          <ColorSwatch name="wizard" />
        </Flex>
      </Box>
    </Grid>
      
    <Divider />

    <Grid variant="base">
      <Box>
        <Text variant="label" sx={{ mb: 3, color: "grayMedium" }}>Text</Text>
        <Text variant="display">Display</Text>
        <Text variant="title01">Title 01</Text>
        <Text variant="title02">Title 03</Text>
        <Text variant="title03">Title 02</Text>
        <Text variant="label">Label</Text>
        <Text variant="body">Body</Text>
      </Box>
    </Grid>

    <Divider />

    <Grid variant="base">
      <Box>
        <Text variant="label" sx={{ mb: 3, color: "grayMedium" }}>Button</Text>
        <Button variant="primary" sx={{ mr: 2, mb: 2 }}>Button</Button>
        <Button variant="secondary" sx={{ mr: 2, mb: 2 }}>Button</Button>
        <Button variant="tertiary" sx={{ mr: 2, mb: 2 }}>Button</Button>
      </Box>
    </Grid>

    <Divider />

    <Grid variant="base">
      <Box>
        <Text variant="label" sx={{ mb: 3, color: "grayMedium" }}>Input</Text>
        
        <Box as="form">
          <Grid>
            <Box>
              <Label htmlFor="test-text">Text</Label>
              <Input type="text" name="test-text" id="test-text" />
            </Box>

            <Box>
              <Label htmlFor="test-email">Email</Label>
              <Input type="email" name="test-email" id="test-email" />
            </Box>

            <Box>
              <Label htmlFor="test-number">Number</Label>
              <Input type="number" name="test-number" id="test-number" />
            </Box>

            <Box>
              <Label htmlFor="test-password">Password</Label>
              <Input type="password" name="test-password" id="test-password" />
            </Box>
          </Grid>
        </Box>
      </Box>
    </Grid>
  </Layout>
)

export default StyleGuidePage
